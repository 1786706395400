import { Suspense, lazy, useEffect } from "react";

import "./scss/style.scss";
import "react-phone-input-2/lib/style.css";
import "./language";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-loading-skeleton/dist/skeleton.css";
import { google_client_id } from "./environment";
import { GoogleOAuthProvider } from "@react-oauth/google";

const AppLayout = lazy(() => import("./layouts"));
const AppProvider = lazy(() => import("./provider"));
const AppRoutes = lazy(() => import("./routes"));

function App() {
  useEffect(() => {
    document.body.style.overflow = "unset";
  }, []);
  return (
    <Suspense>
      {/* Wrap each lazy-loaded component with Suspense */}
      <GoogleOAuthProvider clientId={google_client_id}>
        <AppProvider>
          <Suspense>
            <AppLayout>
              {/* Wrap nested lazy-loaded components with Suspense */}
              <Suspense>
                <AppRoutes />
              </Suspense>
            </AppLayout>
          </Suspense>
        </AppProvider>
      </GoogleOAuthProvider>
    </Suspense>
  );
}

export default App;
